import { useState, useLayoutEffect, memo } from "react";
import Wheel from "@uiw/react-color-wheel";
// cmp
import ColorTemplatesRgb from "./color-templates-rgb";
// hooks
import useIsSmallScreen from "../hooks/useIsSmallScreen";
// services
import { rgbToHex } from "../services/color";
// types
import type { ColorRGB } from "../types/misc";

type Props = Readonly<{
	outerLayoutHeight: number;
	color: ColorRGB;
	onColorChange: (color: ColorRGB) => void;
}>;

const ColorPicker = ({ outerLayoutHeight, color, onColorChange }: Props) => {
	const isSmallScreen = useIsSmallScreen();

	const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

	useLayoutEffect(() => {
		const handleResizeChange = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		handleResizeChange();

		window.addEventListener("resize", handleResizeChange);

		return () => {
			window.removeEventListener("resize", handleResizeChange);
		};
	}, []);

	/**
	 * 16 = 2 * 8px padding for wheel pointer
	 * 32 = 2 * 16px MuiCardContent padding
	 * 16 = save area height
	 */
	const colorWheelSize = isSmallScreen ? Math.min(300, windowSize.width - 16 - 32, windowSize.height - outerLayoutHeight - 16) : 300;

	return (
		<>
			<div id="color-wheel" style={{ width: `${colorWheelSize}px`, margin: "12px auto 24px" }}>
				<Wheel
					width={colorWheelSize}
					height={colorWheelSize}
					color={rgbToHex(color)}
					onChange={({ rgb }) => (onColorChange(rgb))}
				/>
			</div>
			<div style={{ display: "flex", justifyContent: "space-between", padding: "3px 6px 9px" }}>
				<ColorTemplatesRgb onColorClick={onColorChange} />
			</div>
		</>
	);
};

export default memo(ColorPicker);
