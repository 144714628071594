import { useEffect, useCallback, memo } from "react";
import { Portal, Snackbar, Alert, Button } from "@mui/material";
// services
import Analytics from "@local/analytics";
// types
import type { SyntheticEvent } from "react";
import type { AlertColor, SnackbarCloseReason, ButtonProps } from "@mui/material";
import type { Theme, SxProps } from "@mui/material/styles";

const SNACKBAR_SX = {
	left: "calc(8px + env(safe-area-inset-left))",
	right: "calc(8px + env(safe-area-inset-right))",
	bottom: {
		xs: "calc(64px + env(safe-area-inset-bottom))",
		sm: "calc(24px + env(safe-area-inset-bottom))",
	},
} as const satisfies SxProps<Theme>;

type Props = Readonly<{
	autoHideDuration?: number | null;
	severity: AlertColor;
	message: string;
	actionButtonProps?: Omit<ButtonProps, "variant" | "color" | "size">;
	actionButtonText?: string;
	sendAnalytics?: boolean;
} & (
	{
		open: boolean;
		onClose: (open: false) => void;
	} | {
		open: number | string | undefined;
		onClose: (open: undefined) => void;
	}
)>;

const Toast = ({ autoHideDuration = undefined, severity, message, actionButtonProps, actionButtonText, sendAnalytics, open, onClose }: Props) => {
	const isBoolean = typeof open === "boolean";
	const returnValue = isBoolean ? false : undefined;

	useEffect(() => {
		if (sendAnalytics && ((typeof open === "boolean") ? open : open !== undefined)) {
			Analytics.emitAction("errorMessage", { errorMessage: message });
		}
	}, [open, message, sendAnalytics]);

	const handleSnackbarClose = useCallback((_event: SyntheticEvent | Event, reason: SnackbarCloseReason) => {
		if (reason === "clickaway") {
			return;
		}
		onClose(returnValue);
	}, [returnValue, onClose]);

	const handleAlertClose = useCallback(() => (onClose(returnValue)), [returnValue, onClose]);

	return (
		<Portal>
			<Snackbar
				key={isBoolean ? undefined : open}
				autoHideDuration={autoHideDuration}
				open={isBoolean ? open : open !== undefined}
				onClose={handleSnackbarClose}
				sx={SNACKBAR_SX}
			>
				<Alert
					severity={severity}
					action={(actionButtonProps && actionButtonText) ? <Button color="inherit" size="small" {...actionButtonProps}>{actionButtonText}</Button> : undefined}
					onClose={handleAlertClose}
				>
					{message}
				</Alert>
			</Snackbar>
		</Portal>
	);
};

export default memo(Toast);
