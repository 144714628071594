import { memo } from "react";
import { Fab } from "@mui/material";

type Props = Readonly<{
	color: string;
	large?: boolean;
	onClick?: () => void;
	[key: string]: any;
}>;

const ColorButton = ({ color, large = false, onClick = undefined, ...other }: Props) => {
	const size = large ? "48px" : "30px";

	return (
		<Fab
			data-color={color}
			className="btn-color"
			onClick={onClick}
			disableRipple={onClick === undefined}
			disableFocusRipple={onClick === undefined}
			{...other}
			sx={{
				"boxShadow": "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important",
				"backgroundColor": color,
				"&:hover, &:active": {
					backgroundColor: color,
				},
				"width": size,
				"height": size,
				"minHeight": size,
				"cursor": (onClick === undefined) ? "inherit" : undefined,
			}}
		>
			&nbsp;
		</Fab>
	);
};

export default memo(ColorButton);
