import { memo } from "react";
import { Box } from "@mui/material";
// types
import type { ColorHex } from "../types/misc";

type Props = Readonly<{
	color: ColorHex;
}>;

const ColorCircle = ({ color }: Props) => (
	<Box
		component="span"
		data-color={color}
		sx={{
			display: "inline-block",
			width: "18px",
			height: "18px",
			borderRadius: "50%",
			border: "1px solid rgb(117, 117, 117)",
			verticalAlign: "text-bottom",
			backgroundColor: color,
		}}
	/>
);

export default memo(ColorCircle);
